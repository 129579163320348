<template>
  <div class="layout">
    <van-address-list
      v-if="list.length > 0 && !loading"
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @edit="onEdit"
      @select="onSelect"
    />
    <van-loading v-else-if="loading" />
    <p v-else>您还没有添加地址</p>

    <div class="btn_bg">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <button @click="onAdd"><van-icon name="plus" />新建收货地址</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "addAddress",

  data() {
    return {
      name: "",
      chosenAddressId: "",
      list: [],
      loading: true,
    };
  },

  mounted() {},
  created() {
    this.getAddress();
  },

  methods: {
    async getAddress() {
      const data = await this.$API.user.queryAddress({
        pageNum: 1,
        pageSize: 10,
      });
      let res = data.data.data;
      res.forEach((item) => {
        item.address =
          item.province + item.city + item.county + item.detailAddress;
        item.name = item.nameAddress;
        item.tel = item.phoneAddress;
        item.id = item.id;
        if (item.defaultAddress) this.chosenAddressId = item.id;
      });
      this.list = res;
      this.loading = false;
    },
    onAdd() {
      let query = {};
      if (this.list.length) {
        query.isDefault = 0;
      } else {
        query.isDefault = 1;
      }
      if (this.$route.query.isConfirmAnOrder) query.isConfirmAnOrder = 1;
      this.$router.push({
        path: "addAddress",
        query: query,
      });
    },
    onEdit(item, index) {
      let address = {
        id: item.id,
        province: item.province,
        city: item.city,
        county: item.county,
        detailAddress: item.detailAddress,
        nameAddress: item.name,
        phoneAddress: item.tel,
        defaultAddress: item.defaultAddress,
      };
      sessionStorage.setItem("address", JSON.stringify(address));
      this.$router.push({
        path: "addAddress",
        query: this.$route.query.isConfirmAnOrder
          ? { isConfirmAnOrder: 1 }
          : {},
      });
    },
    onSelect(item, index) {
      if (item.id === this.chosenAddressId) return;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        let obj = {
          addressCode: item.addressCode,
          city: item.city,
          consignee: item.consignee,
          country: item.country,
          county: item.county,
          defaultAddress: true,
          detailAddress: item.detailAddress,
          id: item.id,
          nameAddress: item.nameAddress,
          phoneAddress: item.phoneAddress,
          province: item.province,
        };
        const data = await this.$API.user.updateAddress({
          object: obj,
        });
        this.Toast.success("更改默认地址成功");
        this.getAddress();
        if (this.$route.query.isConfirmAnOrder) {
          this.$router.go(-1);
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  position: absolute;
  background: #f5f5f5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 46px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  p {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    color: #333;
    font-size: 14px;
  }
  .van-loading {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
.btn_bg button {
  width: 80%;
  border: none;
  outline: none;
  padding: 12px 0;
  color: #fff;
  background: #009a4d;
  border-radius: 20px;
}
.btn_bg {
  position: fixed;
  bottom: 0;
  background: #fff;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0 auto;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_bg button .van-icon {
  margin-right: 10px;
}
.btn_bg > .van-icon {
  margin-right: 16px;
  font-size: 20px;
}
/deep/.van-address-list__bottom {
  display: none;
}
.van-address-list {
  overflow-y: scroll;
  padding-bottom: 62px;
}
/deep/.van-address-list .van-tag--danger {
  background-color: #009a4d;
}
/deep/.van-address-list .van-address-item .van-radio__icon--checked .van-icon {
  background-color: #009a4d;
  border-color: #009a4d;
}
</style>
